import React from "react"
import PropTypes from "prop-types"
import { Container, Nav, Navbar, Link } from 'react-bootstrap'
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from '../../images/ap_logo.png'

function Layout(props) {
  return (
    <React.Fragment>
      <Container fluid>
        <Nav>
          <Nav.Link as={Navbar.Brand} className="brand-name" href="/dams">
            America's Dams: A Legacy of Neglect
          </Nav.Link>

          <Nav>
            <Nav.Item>
              <Nav.Link className="about-link" title="About" href="about.html">
                <FontAwesomeIcon icon={faQuestionCircle} />
              </Nav.Link>
            </Nav.Item>
          </Nav>

          <Nav.Link as={Navbar.Brand} className="ap-brand-logo" href="/dams">
            <img src={logo} width="40" className="d-inline-block align-top" />
          </Nav.Link>
        </Nav>
      </Container>
      <Container>{props.children}</Container>
    </React.Fragment>
  )
}

export default Layout

import React from "react"
import PropTypes from "prop-types"
import DocLink from "./DocLink"


function sortDocs(doc1, doc2) {
  // First sort by name (ASC)
  if (doc1.name > doc2.name) return 1;
  if (doc1.name < doc2.name) return -1;

  // Then sort by year (DESC)
  if (doc1.year > doc2.year) return -1;
  if (doc1.year < doc2.year) return 1;

  // Then sort by index (ASC)
  if (doc1.index > doc2.index) return 1;
  if (doc1.index < doc2.index) return -1;

  return 0;
}

function DocTable(props) {
  const {
    documents
  } = props;

  const rows = props.documents
    .sort(sortDocs)
    .map((doc) => {
      const idx = doc.index ? ` (${doc.index})` : '';
      const display = `${doc.name}${idx}`;

      return (
        <tr key={doc.id}>
          <td>
            <DocLink
              name={display}
              id={doc.id}
            />
          </td>
          <td>{doc.year}</td>
        </tr>
      );
    });

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Document</th>
          <th scope="col">Year</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

DocTable.propTypes = {
  documents: PropTypes.array,
};

DocTable.defaultProps = {
  documents: [],
};

export default DocTable

import React from "react"
import PropTypes from "prop-types"
import DamSelector from "./DamSelector"
import TileMap from "./TileMap"
import { DamInfo, StateInfo } from "./embed_info"
import IntroText from "../IntroText"
import { Container, Row, Col } from 'react-bootstrap'
import Layout from '../Layout'

class DamsApp extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      states: [],
      selectedState: null,
      selectedDam: null,
    };

    this.onStateSelect = this.onStateSelect.bind(this);
    this.onDamSelect = this.onDamSelect.bind(this);
    this.onUnselect = this.onUnselect.bind(this);
  }

  componentDidMount() {
    fetch(`/api/v1/us`)
      .then(results => results.json())
      .then(data => this.setState({ states: data.states }));
  }

  onStateSelect(state) {
    fetch(`/api/v1/us/${state.abbr}`)
      .then(results => results.json())
      .then(data => this.setState({ selectedState: data.state, selectedDam: null, }));
  }

  onDamSelect(dam) {
    fetch(`/api/v1/dams/${dam.id}`)
      .then(results => results.json())
      .then(data => this.setState({ selectedDam: data.dam, selectedState: null, }));
  }

  onUnselect() {
    this.setState({
      selectedDam: null,
      selectedState: null,
    });
  }

  render() {
    const {
      embed,
    } = this.props;

    const {
      states,
      selectedState,
      selectedDam,
    } = this.state;

    const stateInfo = !selectedState ? null : (
      <StateInfo
        {...selectedState}
        embed={embed}
        onClose={this.onUnselect}
      />
    );
    const damInfo = !selectedDam ? null : (
      <DamInfo
        {...selectedDam}
        embed={embed}
        onClose={this.onUnselect}
      />
    );
    const tileMap = !states.length ? null : (
      <TileMap
        data={states}
        selectedState={selectedState}
        onStateSelect={this.onStateSelect}
      />
    );

    const intro = embed ?  null : <IntroText />

    const body = (
      <Container>
        <Row>
          <Col className="justify-content-md-center mb-3">{intro}</Col>
        </Row>
        <Row>
          <Col className="col-md-12">
            <DamSelector
              embed={embed}
              selected={selectedDam}
              onDamSelect={this.onDamSelect}
            />
            {tileMap}
            {stateInfo}
            {damInfo}
          </Col>
        </Row>
      </Container>
    )

    if (embed) {
      return body
    } else {
      return <Layout>{body}</Layout>
    }
  }
}

DamsApp.propTypes = {
  embed: PropTypes.bool,
};

DamsApp.defaultProps = {
  embed: false,
};

export default DamsApp

import React from 'react'
import { render } from 'react-dom'
import { DamsIndex } from './components'
import 'scss/application.scss'
import 'bootstrap/dist/css/bootstrap.min.css'

render(
  <DamsIndex embed />,
  document.getElementById('app')
)

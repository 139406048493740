import React from "react"
import PropTypes from "prop-types"


function FailureTable(props) {
  const {
    failures,
  } = props;

  const rows = failures.map((failure) => {
      return (
        <tr key={failure.id}>
          <td>{failure.date}</td>
          <td>{failure.mode}</td>
        </tr>
      );
    });

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Failure Mode</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

FailureTable.propTypes = {
  failures: PropTypes.array,
};

FailureTable.defaultProps = {
  failures: [],
};

export default FailureTable

import React from "react"
import PropTypes from "prop-types"


function displayName(name, year, index) {
  const nm = name || "Unknown";
  const idx = index ? `, ${index}` : '';
  const yr = year ? ` (${year})` : '';
  return `${nm}${yr}${idx}`;
}

function DocLink(props) {
  const {
    name,
    year,
    index,
  } = props;

  const body = displayName(name, year, index);

  return (
    <a
      target="_blank"
      href={`/docs/${props.id}`}
    >
      {body}
    </a>
  );
}

DocLink.propTypes = {
  id: PropTypes.number.isRequired,
  name: PropTypes.string,
  year: PropTypes.number,
  index: PropTypes.number,
  icon: PropTypes.bool,
};

DocLink.defaultProps = {
  year: null,
  index: null,
  icon: false,
};

export default DocLink

import React from "react"
import PropTypes from "prop-types"
import DocTable from "./DocTable"
import FailureTable from "./FailureTable"
import DamText from "./DamText"

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class DamInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preshow: true,
    };
  }

  componentDidMount() {
    this.setState({
      preshow: false,
    });
  }

  render() {
    const {
      name,
      uid,
      inspection_date,
      eap_revision_date,
      hazard_standardized_name,
      condition_assessment_standardized_name,
      documents,
      failures,
      embed,
      onClose,
    } = this.props;

    const {
      preshow,
    } = this.state;

    const archive = (
      <a
        target="_blank"
        href={`/dams/${this.props.id}/download`}
      >
        download a full archive of the dam's documents
      </a>
    );

    const noDocsText = null;
    const docText = (
      <p>
        Browse the documents associated with {name} below or {archive}.
      </p>
    );

    const text = documents.length > 0 ? docText : noDocsText;

    const containerClass = embed ? "container-fluid" : "container";

    const docTable = documents.length > 0 && (
      <DocTable
        documents={documents}
      />
    );

    const failureTable = failures.length > 0 && (
      <FailureTable
        failures={failures}
      />
    );

    return (
      <div className={`embed-info ${preshow ? "preshow" : ""}`}>
        <div className={containerClass}>
          <div className="info-controls">
            <span
              onClick={onClose}
              className="info-controls-icon"
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
          <div className="info-body">
            <h2>{name} ({uid})</h2>
            <DamText {...this.props} />
            {text}
            {docTable}
            {failureTable}
          </div>
        </div>
      </div>
    );
  }
}

DamInfo.propTypes = {
  id: PropTypes.any.isRequired,
  name: PropTypes.string.isRequired,
  uid: PropTypes.string.isRequired,
  inspection_date: PropTypes.string,
  eap_revision_date: PropTypes.string,
  hazard_standardized_name: PropTypes.string,
  condition_assessment_standardized_name: PropTypes.string,
  documents: PropTypes.array,
  failures: PropTypes.array,
  embed: PropTypes.bool,
  onClose: PropTypes.func,
};

DamInfo.defaultProps = {
  inspection_date: null,
  eap_revision_date: null,
  hazard_standardized_name: null,
  condition_assessment_standardized_name: null,
  documents: [],
  failures: [],
  embed: false,
  onClose: () => {},
};

export default DamInfo

import React from "react"
import PropTypes from "prop-types"

function DocDate(props) {
  const { dateString } = props

  const date = new Date(dateString)
  const dateOptions = {
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    timeZone: 'UTC',
  }

  return date.toLocaleString('en-US', dateOptions)
}

DocDate.propTypes = {
  dateString: PropTypes.string.isRequired
};

DocDate.defaultProps = {
};

export default DocDate

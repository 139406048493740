import React from "react"
import PropTypes from "prop-types"
import DocDate from "./DocDate"

function DamText(props) {
  const {
    name,
    hazard_standardized_name,
    condition_assessment_standardized_name,
    inspection_date,
    eap_revision_date,
  } = props;

  const intro_text = (
    `According to an analysis by the Associated Press, ${name}`
  );

  const insp = {
    value: inspection_date,
    text: `was last inspected on ${inspection_date}`
    //text: `was last inspected on ` + <DocDate dateString={inspection_date} />
  };
  const eap = {
    value: eap_revision_date,
    text: `had its EAP last revised on ${eap_revision_date}`
    //text: `had its EAP last revised on ` + <DocDate dateString={eap_revision_date} />
  };

  const hazard = {
    value: hazard_standardized_name,
    text: `a hazard rating of "${hazard_standardized_name}"`
  };
  const condition = {
    value: condition_assessment_standardized_name,
    text: `a condition assessment rating of "${condition_assessment_standardized_name}"`
  };

  const date_text = [insp, eap]
    .filter(v => v.value)
    .map(v => v.text)
    .join(" and ");

  const rating_text_body = [hazard, condition]
    .filter(v => v.value)
    .map(v => v.text)
    .join(" and ");
  const rating_text = rating_text_body && `has ${rating_text_body}`;

  const body_text = [date_text, rating_text]
    .filter(v => v)
    .join(". It ");

  let body = null;
  if (body_text) {
    body = (
      <p>
        {`${intro_text} ${body_text}.`}
      </p>
    );
  }

  return <React.Fragment>{body}</React.Fragment>;
}

DamText.propTypes = {
  name: PropTypes.string.isRequired,
  inspection_date: PropTypes.string,
  eap_revision_date: PropTypes.string,
  hazard_standardized_name: PropTypes.string,
  condition_assessment_standardized_name: PropTypes.string,
};

DamText.defaultProps = {
  inspection_date: null,
  eap_revision_date: null,
  hazard_standardized_name: null,
  condition_assessment_standardized_name: null,
};

export default DamText

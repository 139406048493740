import React from "react"
import PropTypes from "prop-types"
import DamTable from "./DamTable"
import DocTable from "./DocTable"
import DamFailureTable from "./DamFailureTable"

import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

class StateInfo extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      preshow: true,
    };
  }

  componentDidMount() {
    this.setState({
      preshow: false,
    });
  }

  render() {
    const {
      name,
      abbr,
      responded,
      intro_blurb,
      suggestion_blurb,
      documents,
      dams,
      embed,
      onClose,
    } = this.props;

    const {
      preshow,
    } = this.state;

    let text;
    if (responded) {
      if (dams.length === 0) {
        text = `${name} does not have any high hazard dams in poor or unsatisfactory condition`;
        if (documents.length > 0) {
          text += (
            ', but it does have dam-related documents that you can browse ' +
            'below.'
          );
        } else {
          text += '.';
        }
      } else {
        if (dams.length === 1) {
          text = `
            ${name} has one high hazard dam in poor or unsatisfactory
            condition. You can find it below, inspect its associated documents,
            and download individual documents or an archive containing all
            documents for the dam.
          `;
        } else if (dams.length > 1) {
          text = `
            ${name} has ${dams.length} dams in poor or unsatisfactory
            condition. You can browse the dams below, inspect their associated
            documents, and download individual documents or archives containing
            all documents for a specific dam.
          `;
        }
        if (documents.length > 0) {
          text += `
            You can also browse ${name}'s other dam-related documents.
          `;
        }
      }
    } else {
      text = `
        ${name} did not provide the AP with enough information to ascertain
        its number of high hazard dams in poor or unsatisfactory condition.
      `;
    }

    let archive = null;
    if (dams.length > 0 || documents.length > 0) {
      archive =(
        <p>
          <a
            target="_blank"
            href={`/states/${abbr}/download`}
          >
            Download a full archive of {name}'s dam-related documents
          </a>
        </p>
      );
    }

    const sugg_text = suggestion_blurb && suggestion_blurb.split('\n\n').map((graf, i) => {
      return <p key={i}>{graf}</p>;
    });
    const suggestion = suggestion_blurb && (
      <React.Fragment>
        <h3>Dams Suggested for Further Reporting</h3>
        {sugg_text}
      </React.Fragment>
    );

    let docTable = null;
    if (documents.length > 0) {
      docTable = (
        <React.Fragment>
          <h3>Statewide Dam-related Documents</h3>
          <DocTable
            documents={documents}
          />
        </React.Fragment>
      );
    }

    let damTable = null;
    const docDams = dams.filter(d => d.documents.length);
    if (docDams.length > 0) {
      damTable = (
        <React.Fragment>
          <h3>Dams</h3>
          <DamTable
            dams={docDams}
          />
        </React.Fragment>
      );
    }

    let failuresTable = null;
    const failureDams = dams.filter(d => d.failures.length);
    if (failureDams.length > 0) {
      failuresTable = (
        <React.Fragment>
          <h3>Dam Failures</h3>
          <DamFailureTable
            dams={failureDams}
          />
        </React.Fragment>
      );
    }

    const ptext = <p>{text}</p>;

    const intro_text = intro_blurb && intro_blurb.split('\n\n').map((graf, i) => {
      return <p key={i}>{graf}</p>;
    });

    const containerClass = embed ? "container-fluid" : "container";

    return (
      <div className={`embed-info ${preshow ? "preshow" : ""}`}>
        <div className={containerClass}>
          <div className="info-controls">
            <span
              onClick={onClose}
              className="info-controls-icon"
            >
              <FontAwesomeIcon icon={faTimes} />
            </span>
          </div>
          <div className="info-body">
            <h2>{name}</h2>
            {intro_text || ptext}
            {suggestion}
            {archive}
            {docTable}
            {damTable}
            {failuresTable}
          </div>
        </div>
      </div>
    );
  }
}

StateInfo.propTypes = {
  name: PropTypes.string.isRequired,
  abbr: PropTypes.string.isRequired,
  responded: PropTypes.bool,
  intro_blurb: PropTypes.string,
  suggestion_blurb: PropTypes.string,
  documents: PropTypes.array,
  dams: PropTypes.array,
  embed: PropTypes.bool,
  onClose: PropTypes.func,
};

StateInfo.defaultProps = {
  responded: true,
  intro_blub: null,
  suggestion_blurb: null,
  documents: [],
  dams: [],
  embed: false,
  onClose: () => {},
};

export default StateInfo

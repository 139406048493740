import React from "react"
import PropTypes from "prop-types"


function ArchiveLink(props) {
  return (
    <a
      target="_blank"
      className="link-button"
      href={`/${props.urlPrefix}/${props.id}/download`}
    >
      <i className="fa fa-download"></i>
    </a>
  );
}

ArchiveLink.propTypes = {
  id: PropTypes.number.isRequired,
  urlPrefix: PropTypes.string.isRequired,
};

export default ArchiveLink

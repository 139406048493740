import React from "react"
import PropTypes from "prop-types"
import { AsyncPaginate } from "react-select-async-paginate"

async function loadOptions(inputValue, loadedOptions, { page }) {
  const response = await fetch(`/api/v1/dams?name=${inputValue}&page=${page}`);
  const results = await response.json();

  return {
    options: results.dams,
    hasMore: false, // !!results.meta.pagination.links.next,
    additional: {
      page: page + 1
    }
  };
}


function DamSelector(props) {
  const {
    embed,
    selected,
    onDamSelect,
  } = props;

  const theme = (base) => {
    return {
      ...base,
      colors: {
        ...base.colors,
        primary: "#333",
        primary75: "#666",
        primary50: "#aaa",
        primary25: "#eee",
      }
    };
  };

  const text = (
    <p className="lead">
      Select a specific dam or click on a state below
    </p>
  );

  return (
    <div className="mb-3">
      {embed ? null : text}
      <AsyncPaginate
        loadOptions={loadOptions}
        additional={{ page: 1 }}
        defaultOptions
        theme={theme}
        value={selected}
        placeholder="Select a dam..."
        getOptionLabel={opt => `${opt.name}, ${opt.state.abbr}`}
        getOptionValue={opt => opt.id}
        onChange={onDamSelect}
      />
    </div>
  );
}

DamSelector.propTypes = {
  embed: PropTypes.bool,
  selected: PropTypes.shape({
    id: PropTypes.any,
    name: PropTypes.string,
    state: PropTypes.object,
  }),
  onDamSelect: PropTypes.func,
};

DamSelector.defaultProps = {
  embed: false,
  selected: null,
  onDamSelect: () => {},
};

export default DamSelector

import React from "react"
import PropTypes from "prop-types"


function DamFailureTable(props) {
  const {
    dams,
  } = props;

  const rows = dams.map((dam) => {
    const fails = dam.failures.map((failure, i) => {
      return (
        <tr key={i}>
          <td>{dam.name}</td>
          <td>{dam.uid}</td>
          <td>{failure.date}</td>
          <td>{failure.mode}</td>
        </tr>
      );
    });
    return (
      <React.Fragment key={dam.id}>
        {fails}
      </React.Fragment>
    );
  });

  return (
    <table className="table">
      <thead>
        <tr>
          <th scope="col">Dam</th>
          <th scope="col">NID ID</th>
          <th scope="col">Failure Date</th>
          <th scope="col">Failure Mode</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  );
}

DamFailureTable.propTypes = {
  dams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    failures: PropTypes.array
  }))
};

DamFailureTable.defaultProps = {
  dams: [],
};

export default DamFailureTable

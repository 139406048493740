import React from "react"
import PropTypes from "prop-types"
import DocLink from "./DocLink"
import DocDate from "./DocDate"
import ArchiveLink from "./ArchiveLink"

import { faTimes, faCopy } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


function intersperse(arr, sep) {
  if (arr.length === 0) {
    return [];
  }

  return arr.slice(1).reduce((xs, x, i) => {
    return xs.concat([sep, x]);
  }, [arr[0]]);
}


class DamTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedDam: null,
    };

    this.selectDam = this.selectDam.bind(this);
  }

  selectDam(dam_id) {
    this.setState({
      selectedDam: dam_id,
    });
  }

  render() {
    const {
      dams,
    } = this.props;

    const {
      selectedDam,
    } = this.state;

    const rows = dams.map((dam) => {
      const docs = dam.documents.map((doc) => {
        return (
          <DocLink
            key={doc.id}
            {...doc}
          />
        );
      });

      const archive = (
        <ArchiveLink
          urlPrefix="dams"
          id={dam.id}
        />
      );

      const active = dam.id == selectedDam;
      const cn = active ? "dam-docs-row active" : "dam-docs-row";
      const icon = active ? faTimes : faCopy;

      const key = active ? null : dam.id;
      const clickCallback = () => this.selectDam(key);

      return (
        <React.Fragment key={dam.id}>
          <tr>
            <td>{dam.name}</td>
            <td>{dam.inspection_date && <DocDate dateString={dam.inspection_date} />}</td>
            <td>{dam.eap_revision_date && <DocDate dateString={dam.eap_revision_date} />}</td>
            <td><code>{dam.hazard_standardized_name}</code></td>
            <td><code>{dam.condition_assessment_standardized_name}</code></td>
            <td>
              <span
                className="link-button"
                onClick={clickCallback}
              >
                <FontAwesomeIcon icon={icon} />
              </span>
            </td>
          </tr>
          <tr className={cn}>
            <td></td>
            <td colSpan={4}>Documents: {intersperse(docs, ', ')}</td>
            <td>{archive}</td>
          </tr>
        </React.Fragment>
      );
    });

    return (
      <table className="table">
        <thead>
          <tr>
            <th scope="col">Dam</th>
            <th scope="col">Last Inspection</th>
            <th scope="col">Last EAP Revision</th>
            <th scope="col">Hazard</th>
            <th scope="col">Condition</th>
            <th scope="col">Docs</th>
          </tr>
        </thead>
        <tbody>
          {rows}
        </tbody>
      </table>
    );
  }
}

DamTable.propTypes = {
  dams: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    documents: PropTypes.array
  }))
};

DamTable.defaultProps = {
  dams: [],
};

export default DamTable

import React from "react"
import PropTypes from "prop-types"
import { mouse } from "d3"
import { values } from "d3-collection"
import { select } from "d3-selection"
import { max, min } from "d3-array"
import { scaleQuantize } from "d3-scale"
import colorbrewer from "colorbrewer"


class TileMap extends React.Component {
  constructor(props) {
    super(props);
    this.drawMap = this.drawMap.bind(this);
  }

  componentDidMount() {
    this.drawMap();
    window.addEventListener("resize", this.drawMap);
  }

  componentDidUpdate() {
    this.drawMap();
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.drawMap);
  }

  updateScaleRange() {
    // Get the colorset and number
    const palette = this.props.colorSet;
    const number = this.props.colorNumber;

    // Set the range
    this.props.scale.range(colorbrewer[palette][number]);
  }

  updateScaleDomain(data) {
    // Get the min and max form the user's data
    const dataMin = min(data, d => d.data);
    const dataMax = max(data, d => d.data);

    // Set the scale domain
    this.props.scale.domain([dataMin, dataMax]);
  }

  drawMap() {
    const node = this.node;

    const selState = this.props.selectedState;

    const data = this.props.data.map((stateData) => {
      const state = this.props.states[stateData.abbr];
      return !state ? null : {
        abbr: state.abbr,
        ap: state.ap,
        full: state.full,
        x: state.x,
        y: state.y,
        w: state.w,
        h: state.h,
        data: stateData.dams.length,
        responded: stateData.responded,
        selected: selState && state.abbr === selState.abbr,
      };
    }).filter(d => d);

    this.updateScaleRange();
    this.updateScaleDomain(data);

    const width = node.getBoundingClientRect().width;
    const height = width * 0.66371681415;

    const svg = select(node).select('svg');

    svg
      .attr('width', width)
      .attr('height', height)
      .style('overflow', 'visible')
      .attr('viewBox', '0 0 858.8 570'); // Needed to make the map scale to the SVG size

    const tooltip = svg.select('.tooltip');

    const map = svg.select('.map')
      .selectAll('g')
      .data(data);

    map.style("stroke-width", d => d.selected ? 3 : 0);

    const mapStates = map
      .enter()
      .append('g')
      .style("stroke-width", d => d.selected ? 3 : 0)
      .on("click", this.props.onStateSelect)
      .on("mouseover", () => {
        tooltip.style("opacity", 1)
      })
      .on("mouseout", () => {
        tooltip.style("opacity", 0)
      })
      .on("mousemove", function(d) {
        const x = mouse(this)[0] - 5;
        const y = mouse(this)[1] - 40;
        tooltip.attr("transform", "translate(" + x + "," + y + ")");
        tooltip
          .select("text")
          .text(
            d.responded
              ? `${d.abbr}: ${d.data} dams`
              : `${d.abbr}: no data`
          );
      });

    mapStates
      .append('rect')
      .attr('width', d => d.w)
      .attr('height', d => d.h)
      .attr('x', d => d.x)
      .attr('y', d => d.y)
      .attr('fill', d => d.responded ? this.props.scale(d.data || 0) : "#e0e0e0")
      .style("stroke", "black")
      .style("cursor", "pointer");

    mapStates
      .append('text')
      .text(d => d[this.props.labelStyle])
      .attr('x', d => d.x + (d.w / 2))
      .attr('y', d => d.y + (d.h / 2))
      .attr('class', 'state-label')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .style('cursor', 'pointer')
      .style('fill', this.props.labelColor)
      .style('font-family', this.props.labelTypeface);
  }

  render() {
    return (
      <div
        ref={node => this.node = node}
        className="tile-map-container"
      >
        <svg>
          <g className="map"></g>
          <g className="tooltip">
            <rect
              width="90"
              height="30"
              rx="4"
              ry="4"
              fill="white"
              style={{"stroke": "#e0e0e0"}}
            />
            <text
              x="45"
              y="5"
              dy="1.2em"
              style={{"textAnchor": "middle"}}
              fill="#333"
              fontSize="12px"
              fontWeight="bold"
            />
          </g>
        </svg>
      </div>
    );
  }
}

TileMap.propTypes = {
  width: PropTypes.number,
  colorSet: PropTypes.string,
  colorNumber: PropTypes.number,
  labels: PropTypes.bool,
  labelTypeface: PropTypes.string,
  labelStyle: PropTypes.string,
  labelColor: PropTypes.string,
  data: PropTypes.array,
  scale: PropTypes.func,
  onStateSelect: PropTypes.func,
  selectedState: PropTypes.object,
  states: PropTypes.object,
};

TileMap.defaultProps = {
  width: null,
  colorSet: 'Reds',
  colorNumber: 6,
  labelTypeface: 'sans-serif',
  labelStyle: 'abbr',
  labelColor: 'black',
  data: [],
  scale: scaleQuantize(),
  onStateSelect: () => {},
  selectedState: null,
  states: {
    "AK": {
      "abbr": "AK",
      "full": "Alaska",
      "ap": "Alaska",
      "x": 0,
      "y": 72,
      "w": 66,
      "h": 66,
    },
    "AL": {
      "abbr": "AL",
      "full": "Alabama",
      "ap": "Ala.",
      "x": 504,
      "y": 432,
      "w": 66,
      "h": 66
    },
    "AR": {
      "abbr": "AR",
      "full": "Arkansas",
      "ap": "Ark.",
      "x": 360,
      "y": 360,
      "w": 66,
      "h": 66
    },
    "AZ": {
      "abbr": "AZ",
      "full": "Arizona",
      "ap": "Ariz.",
      "x": 144,
      "y": 360,
      "w": 66,
      "h": 66
    },
    "CA": {
      "abbr": "CA",
      "full": "California",
      "ap": "Calif.",
      "x": 72,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "CO": {
      "abbr": "CO",
      "full": "Colorado",
      "ap": "Colo.",
      "x": 216,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "CT": {
      "abbr": "CT",
      "full": "Connecticut",
      "ap": "Conn.",
      "x": 720,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "DC": {
      "abbr": "DC",
      "full": "Washington, D.C.",
      "ap": "D.C.",
      "x": 648,
      "y": 360,
      "w": 66,
      "h": 66
    },
    "DE": {
      "abbr": "DE",
      "full": "Delaware",
      "ap": "Del.",
      "x": 720,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "FL": {
      "abbr": "FL",
      "full": "Florida",
      "ap": "Fla.",
      "x": 648,
      "y": 504,
      "w": 66,
      "h": 66
    },
    "GA": {
      "abbr": "GA",
      "full": "Georgia",
      "ap": "Ga.",
      "x": 576,
      "y": 432,
      "w": 66,
      "h": 66
    },
    "HI": {
      "abbr": "HI",
      "full": "Hawaii",
      "ap": "Hawaii",
      "x": 72, // from AZ - originally 72 // from CA originally 144 now 72 again
      "y": 504,
      "w": 66,
      "h": 66
    },
    "IA": {
      "abbr": "IA",
      "full": "Iowa",
      "ap": "Iowa",
      "x": 360,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "ID": {
      "abbr": "ID",
      "full": "Idaho",
      "ap": "Idaho",
      "x": 144,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "IL": {
      "abbr": "IL",
      "full": "Illinois",
      "ap": "Ill.",
      "x": 432,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "IN": {
      "abbr": "IN",
      "full": "Indiana",
      "ap": "Ind.",
      "x": 432,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "KS": {
      "abbr": "KS",
      "full": "Kansas",
      "ap": "Kan.",
      "x": 288,
      "y": 360,
      "w": 66,
      "h": 66
    },
    "KY": {
      "abbr": "KY",
      "full": "Kentucky",
      "ap": "Ky.",
      "x": 432,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "LA": {
      "abbr": "LA",
      "full": "Louisiana",
      "ap": "La.",
      "x": 360,
      "y": 432,
      "w": 66,
      "h": 66
    },
    "MA": {
      "abbr": "MA",
      "full": "Massachusetts",
      "ap": "Mass.",
      "x": 720,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "MD": {
      "abbr": "MD",
      "full": "Maryland",
      "ap": "Md.",
      "x": 648,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "ME": {
      "abbr": "ME",
      "full": "Maine",
      "ap": "Maine",
      "x": 792.8,
      "y": 0,
      "w": 66,
      "h": 66
    },
    "MI": {
      "abbr": "MI",
      "full": "Michigan",
      "ap": "Mich",
      "x": 504,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "MN": {
      "abbr": "MN",
      "full": "Minnesota",
      "ap": "Minn.",
      "x": 360,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "MO": {
      "abbr": "MO",
      "full": "Missouri",
      "ap": "Mo.",
      "x": 360,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "MS": {
      "abbr": "MS",
      "full": "Mississippi",
      "ap": "Miss.",
      "x": 432,
      "y": 432,
      "w": 66,
      "h": 66
    },
    "MT": {
      "abbr": "MT",
      "full": "Montana",
      "ap": "Mont.",
      "x": 216,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "NC": {
      "abbr": "NC",
      "full": "North Carolina",
      "ap": "N.C.",
      "x": 504,
      "y": 360,
      "w": 66,
      "h": 66
    },
    "ND": {
      "abbr": "ND",
      "full": "North Dakota",
      "ap": "N.D.",
      "x": 288,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "NE": {
      "abbr": "NE",
      "full": "Nebraska",
      "ap": "Neb.",
      "x": 288,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "NH": {
      "abbr": "NH",
      "full": "New Hampshire",
      "ap": "N.H.",
      "x": 792.8,
      "y": 72,
      "w": 66,
      "h": 66
    },
    "NJ": {
      "abbr": "NJ",
      "full": "New Jersey",
      "ap": "N.J.",
      "x": 648,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "NM": {
      "abbr": "NM",
      "full": "New Mexico",
      "ap": "N.M.",
      "x": 216,
      "y": 360,
      "w": 66,
      "h": 66
    },
    "NV": {
      "abbr": "NV",
      "full": "Nevada",
      "ap": "Nev.",
      "x": 144,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "NY": {
      "abbr": "NY",
      "full": "New York",
      "ap": "N.Y.",
      "x": 648,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "OH": {
      "abbr": "OH",
      "full": "Ohio",
      "ap": "Ohio",
      "x": 504,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "OK": {
      "abbr": "OK",
      "full": "Oklahoma",
      "ap": "Okla.",
      "x": 288,
      "y": 432,
      "w": 66,
      "h": 66
    },
    "OR": {
      "abbr": "OR",
      "full": "Oregon",
      "ap": "Ore.",
      "x": 72,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "PA": {
      "abbr": "PA",
      "full": "Pennsylvania",
      "ap": "Pa.",
      "x": 576,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "PR": {
      "abbr": "PR",
      "full": "Puerto Rico",
      "ap": "P.R.",
      "x": 792.8,
      "y": 504,
      "w": 66,
      "h": 66
    },
    "RI": {
      "abbr": "RI",
      "full": "Rhode Island",
      "ap": "R.I.",
      "x": 792.8,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "SC": {
      "abbr": "SC",
      "full": "South Carolina",
      "ap": "S.C.",
      "x": 576,
      "y": 360,
      "w": 66,
      "h": 66
    },
    "SD": {
      "abbr": "SD",
      "full": "South Dakota",
      "ap": "S.D.",
      "x": 288,
      "y": 216,
      "w": 66,
      "h": 66
    },
    "TN": {
      "abbr": "TN",
      "full": "Tennessee",
      "ap": "Tenn.",
      "x": 432,
      "y": 360,
      "w": 66,
      "h": 66
    },
    "TX": {
      "abbr": "TX",
      "full": "Texas",
      "ap": "Texas",
      "x": 288,
      "y": 504,
      "w": 66,
      "h": 66
    },
    "UT": {
      "abbr": "UT",
      "full": "Utah",
      "ap": "Utah",
      "x": 144,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "VA": {
      "abbr": "VA",
      "full": "Virginia",
      "ap": "Va.",
      "x": 576,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "VT": {
      "abbr": "VT",
      "full": "Vermont",
      "ap": "Vt.",
      "x": 720,
      "y": 72,
      "w": 66,
      "h": 66
    },
    "WA": {
      "abbr": "WA",
      "full": "Washington",
      "ap": "Wash.",
      "x": 72,
      "y": 144,
      "w": 66,
      "h": 66
    },
    "WI": {
      "abbr": "WI",
      "full": "Wisconsin",
      "ap": "Wis.",
      "x": 432,
      "y": 72,
      "w": 66,
      "h": 66
    },
    "WV": {
      "abbr": "WV",
      "full": "West Virginia",
      "ap": "W. Va.",
      "x": 504,
      "y": 288,
      "w": 66,
      "h": 66
    },
    "WY": {
      "abbr": "WY",
      "full": "Wyoming",
      "ap": "Wyo.",
      "x": 216,
      "y": 216,
      "w": 66,
      "h": 66
    }
  }
};

export default TileMap

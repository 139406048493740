import React from "react"
import PropTypes from "prop-types"

class IntroText extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <p className="lead">
      An Associated Press analysis, looking at 45 states that provided hazard and
      inspection report data for their state's dams, found there were more than 1,500
      dangerous dams – both in unsatisfactory or poor condition and in areas where
      a failure could endanger human life – across the country in 2018. More than
      half of them did not have emergency action plans (EAPs) on what should be done
      in an emergency, and 20 percent of the dams' latest inspections were not in
      compliance with their state's required inspection frequency.
      </p>
    );
  }
}

IntroText.propTypes = {
};

IntroText.defaultProps = {
};

export default IntroText

